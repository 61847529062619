<template>
  <FillViewport
    :minExact="true"
    class="basic-info"
    data-scroll-section
    data-scroll-position="bottom"
  >
    <div class="recenter">
      <div class="bubbly-text">
        <div
          data-scroll
          data-scroll-speed="1"
          data-scroll-delay="0.01"
          data-scroll-repeat="true"
          data-scroll-position="bottom"
        >
          <h2>Wo?</h2>
          <p>Galerie Netzwerk</p>
          <p>NEUSTRASSE 10</p>
        </div>
      </div>
      <div class="bubbly-text">
        <div
          data-scroll
          :data-scroll-speed="tooSmall ? 1 : -1"
          data-scroll-delay="0.01"
          data-scroll-repeat="true"
          data-scroll-position="bottom"
        >
          <h2>Wann?</h2>
          <table>
            <tr>
              <td>DIENSTAG</td>
              <td>14-18 Uhr</td>
            </tr>
            <tr>
              <td>DONNERSTAG</td>
              <td>14-18 Uhr</td>
            </tr>
            <tr>
              <td colspan="2">(DANACH HAPPENINGS)</td>
            </tr>
            <tr>
              <td>SAMSTAG</td>
              <td>11-18 Uhr</td>
            </tr>
            <tr>
              <td>SONNTAG</td>
              <td>11-18 Uhr</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="bubbly-fill">
        <a href="https://goo.gl/maps/TcJ61XHosnFKjtq29">Auf Google Maps </a>
      </div>
      <div class="bubbly-fill">
        <a href="https://www.instagram.com/transexposition/">INSTAGRAM :D </a>
      </div>

      <div class="bubbly-fill">
        <a @click="window.lmS.scrollToBottom('eventslider')">Weitere Termine ^</a>
      </div>
      <div class="bubbly-fill">
        <a href="https://www.galerie-netzwerk.de/">galerie-netzwerk.de</a>
      </div>
    </div>
  </FillViewport>
</template>

<script>
import FillViewport from "@/components/FillViewport.vue";
// import locomotive from "@/mixins/locomotive.js";
export default {
  components: {
    FillViewport,
  },
  // mixins: [locomotive],
  data() {
    return {
      tooSmall: true,
    };
  },
  methods: {
    calcWidth() {
      this.tooSmall = window.innerWidth <= 650;
    },
  },
  mounted() {
    this.onresize = window.addEventListener("resize", this.calcWidth);
    this.calcWidth();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onresize);
  },
};
</script>

<style lang="scss">
.basic-info {
  position: relative;
  .recenter {
    top: 0;
    left: 0;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    transform: translatex(50%);
    > div {
      position: absolute;
    }
    > :nth-child(1) {
      //   wo
      top: 50%;
      left: 0.5em;
      --rotation: 15deg;
      --transX: -100%;
      animation: float 10s ease-in-out infinite;
      order: 2;
    }
    > :nth-child(2) {
      //   wann
      top: 60%;
      left: -0.5em;
      --rotation: -24deg;
      --transX: 0%;
      animation: float 5s ease-in-out infinite;
      order: 4;
    }
    > :nth-child(3) {
      //  maps
      top: 85%;
      left: -20%;
      --rotation: 45deg;
      --transX: -100%;
      animation: float 7s ease-in-out infinite;
      order: 3;
    }
    > :nth-child(4) {
      //   insta
      top: 20%;
      left: 15%;
      --rotation: -5deg;
      --transX: -50%;
      animation: float 6s ease-in-out infinite;
      order: 6;
    }
    > :nth-child(5) {
      //   next
      top: 70%;
      left: 25%;
      --rotation: -35deg;
      --transX: 0%;
      animation: float 12s ease-in-out infinite;
      order: 5;
    }
    > :nth-child(6) {
      //   galerie netzwerk
      top: 25%;
      left: -50%;
      --rotation: -65deg;
      --transX: 0%;
      animation: float 4s ease-in-out infinite;
      order: 1;
    }
    @media (max-width: 1400px) {
      > :nth-child(5) {
        //   next
        top: 55%;
        left: 40%;
        --transX: -100%;
      }
      @media (max-width: 1000px) {
        > :nth-child(6) {
          //   galerie netzwerk
          top: 25%;
          left: -65%;
          --rotation: -80deg;
        }
        > :nth-child(3) {
          //  maps
          top: 65%;
          left: -45%;
          --transX: 0%;
        }
      }
      @media (max-width: 750px) {
        > :nth-child(6) {
          //   galerie netzwerk
          top: 10%;
          left: -45%;
          --rotation: -10deg;
        }
        > :nth-child(3) {
          //  maps
          top: 70%;
        }
      }
    }
  }
  @media (max-width: 650px) {
    .bubbly-text div{
      transform: translatey(10vh) !important;
    }
    .recenter {
      padding-top: 10vh;
      // padding-bottom: 20vh;
      transform: none;
      display: flex;
      flex-wrap: wrap;
      height: auto;
      align-items: center;
      justify-content: center;
      :nth-child(n) {
        position: relative;
        top: 0%;
        left: 0%;
        --transX: 0%;
        margin: 1.5vh 1vw;
      }
      :nth-child(2n) {
        --rotation: -10deg;
      }
      :nth-child(2n-1) {
        --rotation: 10deg;
      }
    }
  }
  @media (max-width: 550px) {
    .recenter{
      padding-bottom: 20vh;
    }
  }
  h2,
  p,
  a,
  td {
    font-size: 1rem;
    font-weight: 400;
    color: var(--white);
    text-transform: uppercase;
    text-decoration: none;
  }
  td {
    &:first-child {
      padding-right: 1em;
    }
    
    white-space: nowrap;
  }
}
.bubbly-text,
.bubbly-fill {
  position: absolute;
  display: block;
  width: auto;
}
.bubbly-text div,
.bubbly-fill a {
  border: 2px solid var(--white);
  border-radius: 80px;
  padding: 1em 2em;
  width: auto;
}
.bubbly-fill {
  a {
    display: block;
    padding: 0.5em 1.5em;
    background-color: var(--white);
    color: var(--black);
    white-space: nowrap;
    &:hover,
    &:active {
      color: var(--white);
      background-color: var(--black);
      border-color: var(--black);
    }
  }
}
</style>