import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import locomotiveScroll from "locomotive-scroll";

// Object.defineProperty(Vue.prototype, "locomotiveScroll", {

//     value: locomotiveScroll

//    });
const app = createApp(App)
app.config.globalProperties.locomotiveScroll = locomotiveScroll
app.config.globalProperties.window = window
app.use(router).mount('#app')

console.log(app)
/* eslint-disable */
require("@/external/cookielessanalytics.js");
/* eslint-enable */
