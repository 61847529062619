<template>
  <InlineSvg src="logo.svg" :width="width" :height="height" :fill="fill" :aria-label="ariaLabel" class="logo"></InlineSvg>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  props: [
    'width',
    'height',
    'fill',
    'ariaLabel',
  ],
  components: {
    InlineSvg,
  },
};
</script>

<style>
</style>