<template>
  <div class="event-slide">
    <div class="l">
      <h3
        class="title"
        data-scroll
        data-scroll-position="bottom"
        data-scroll-repeat="true"
      >
        {{ eventData.title ? eventData.title : "tba" }}
      </h3>
      <p
        v-if="eventData.person"
        class="person"
        data-scroll
        data-scroll-position="bottom"
        data-scroll-repeat="true"
      >
        {{ eventData.person }}
      </p>
      <time
        :datetime="eventData.date ? eventData.date : false"
        class="date"
        data-scroll
        data-scroll-position="bottom"
        data-scroll-repeat="true"
        >{{ theDate }}</time
      >
    </div>
    <div class="r">
      <img
        v-if="eventData.img"
        :src="require('@/assets/events/images/' + eventData.img)"
        alt=""
      />
      <img v-else src="eventplaceholder.png" alt="" />
      <time v-if="eventData.time" :datetime="eventData.time" class="time">{{
        eventData.time
      }}</time>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    eventData: {
      default: [],
    },
  },
  computed: {
    theDate() {
      if (this.eventData.date === "tba") {
        return "tba";
      }

      const date = new Date(this.eventData.date);
      return (
        String(date.getDate()).padStart(2, "0") +
        "." +
        String(date.getMonth()+1).padStart(2, "0")
      );
    },
  },
};
</script>

<style lang="scss">
.event-slide {
  width: 100%;
  height: 100%;
  height: calc(9em);
  margin-right: 2em;
  display: flex;
  direction: ltr;
  color: var(--white);

  time {
    font-family: var(--font-effect);
    font-weight: 400;
    font-style: normal;
    text-transform: none;
  }
  .r,
  .l {
    width: 33.33%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;
  }
  .l {
    .is-inview {
      opacity: 1;
      transition-duration: 0.8s;
      transform: perspective(400px) translateY(0%) rotateX(-0deg);
    }
    flex-grow: 1;

    margin-right: 1em;
    time {
      font-size: 3em;
      margin-bottom: -0.5em;
      margin-top: -0.1em;
      transform: perspective(400px) translateY(-75%) rotateX(20deg);
    }
    h3,
    p {
      font-size: 1em;
      font-weight: inherit;
      transform: perspective(400px) translateY(-20%) rotateX(10deg);
    }
    h3,
    p,
    time {
      opacity: 0;
      transform-origin: center bottom;
      transform-style: preserve-3d;
      transition: opacity 0s cubic-bezier(0.215, 0.61, 0.355, 1),
        transform 0s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition-duration: 1s;
    }
  }
  .r {
    img {
      height: 50%;
      flex-grow: 1;
      object-fit: cover;
      -o-object-position: center center;
    }
    time {
      padding-top: 0.5em;
      margin-bottom: -0.4em;
    }
  }
  @media (max-width: 600px) {
    font-size: 5vw;
  }
}
</style>
