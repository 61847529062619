<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <div id="js-scroll" class="container" data-scroll-container>
    <router-view />

    <MouseFollower />
  </div>
</template>
<script>
import MouseFollower from "@/components/MouseFollower.vue";
export default {
  components: { MouseFollower }
};
</script>

<style lang="scss">
@import "locomotive-scroll/dist/locomotive-scroll.min.css";
@import "modern-css-reset/dist/reset.min.css";

@font-face {
  font-family: "jaapokki";
  src: url("@/assets/fonts/jaapokki-regular.eot");
  src: url("@/assets/fonts/jaapokki-regular.eot?#iefix")
      format("embedded-opentype"),
    url("@/assets/fonts/jaapokki-regular.woff") format("woff"),
    url("@/assets/fonts/jaapokki-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Orator Std Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Orator Std Medium"),
    url("@/assets/fonts/OratorStd.woff") format("woff");
  font-display: swap;
}

*::selection {
  background-color: var(--white);
  color: var(--black);
  border-radius: 50%;
}

body {
  --font-effect: "jaapokki", Arial, sans-serif;
  --font-normal: "Orator Std Medium", Arial, sans-serif;
  --spacing-effect: 35;
  --white: #fafafa;
  --black: #181818;
  --teal: #4ab0a2;
  color: var(--black);

  background-color: var(--teal);
  font-family: "Orator Std Medium";
  font-style: normal;
  font-weight: normal;
  text-transform: uppercase;
  hyphens: auto;
  overflow: hidden;
  margin: 0;
  padding: 0;
  span.c-scrollbar{
    display: none;
  }
  span.c-scrollbar:last-child{
    display: block;
  }
}

body:not(.user-is-touching) {
  cursor: none;
}

#js-scroll {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

html,
#app {
  overflow: hidden;
  font-size: 24px;
}

.reverse-order {
  display: flex;
  flex-direction: column-reverse;
}

.offscreen {
  position: absolute;
  clip: rect(1px 1px 1px 1px); /* for Internet Explorer */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

html.has-scroll-smooth {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
}

@keyframes float {
  0% {
    transform: translateY(calc(-2.5vh - 50%)) rotate(var(--rotation))
      translateX(var(--transX));
  }
  50% {
    transform: translateY(calc(2.5vh - 50%)) rotate(var(--rotation))
      translateX(var(--transX));
  }
  100% {
    transform: translateY(calc(-2.5vh - 50%)) rotate(var(--rotation))
      translateX(var(--transX));
  }
}
</style>
