<template>
  <div v-if="!this.USER_IS_TOUCHING">
    <div
      class="mouse-follower"
      :style="{ left: x + 'px', top: y + 'px' }"
    ></div>
    <div class="mouse-pointer" :style="{ left: x + 'px', top: y + 'px' }"></div>
  </div>
</template>

<script>
import { debounce } from "lodash";
export default {
  data() {
    return {
      x: 0,
      y: -1000,
      USER_IS_TOUCHING: false,
    };
  },
  //   watch: {
  //     x(){
  //         this.setTheStyle();
  //     },
  //     y(){
  //         this.setTheStyle();
  //     }
  //   },
  methods: {
    // setTheStyle() {
    //   this.$refs.mF.style = { left: this.x, top: this.y };
    // },
  },
  mounted() {
    this.evl = window.addEventListener(
      "mousemove",
      function (e) {
        //   console.log(e)
        const df = debounce(
          function (x, y) {
            window.requestAnimationFrame(() => {
              this.x = x;
              this.y = y;
            });
          }.bind(this),
          10
        );
        df(e.pageX, e.pageY);
      }.bind(this)
    );
    document.body.classList.remove("user-is-touching");
    window.addEventListener(
      "touchstart",
      function onFirstTouch() { // disable fancy mouse stuff on touch
        document.body.classList.add("user-is-touching");
        this.USER_IS_TOUCHING = true;
        // we only need to know once that a human touched the screen, so we can stop listening now
        window.removeEventListener("touchstart", onFirstTouch, false);
      }.bind(this),
      false
    );
  },
  beforeUnmount() {
    removeEventListener("mousemove", this.evl);
  },
};
</script>

<style lang="scss">
.mouse-follower {
  position: absolute;
  z-index: 900001;
  width: 50vh;
  height: 100vh;
  border-radius: 25vh;
  transform: translate(-50%, -50%);
  background-color: #fff;
  mix-blend-mode: difference;
  pointer-events: none;
  user-select: none;
  transition: all 1s cubic-bezier(0.04, 0.15, 0.33, 1);
}
.mouse-pointer {
  position: absolute;
  z-index: 900002;
  width: 2vmin;
  height: 2vmin;
  border-radius: 1vmin;
  border: 0.5vmin solid #fff;
  transform: translate(-50%, -50%);
  //   background-color: #fff;
  mix-blend-mode: difference;
  pointer-events: none;
  user-select: none;
}
</style>