<template>
  <section data-scroll-section>
    <carousel
      :itemsToShow="itemsToShow"
      :dir="'rtl'"
      :snapAlign="'start'"
      :wrapAround="true"
      :mouseDrag="events.length > 1"
      :touchDrag="events.length > 1"
      
        data-scroll
        data-scroll-position="top"
        data-scroll-repeat="true"
    >
      <slide
        v-for="(event, index) in events"
        :key="index"
      >
        <EventSlide :eventData="event" />
      </slide>
      <!-- eslint-disable-next-line -->
      <template #addons="{ slidesCount }" >
        <!-- <Navigation v-if="slidesCount > 1" /> -->
        <Pagination />
      </template>
    </carousel>
  </section>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
// eslint-disable-next-line
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import EventSlide from "@/components/EventSlide.vue";
import { events } from "@/assets/events/events.js";
let yesterDate = new Date();
yesterDate.setDate(yesterDate.getDate() - 1);
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    // eslint-disable-next-line
    Navigation,
    EventSlide,
  },
  data() {
    return {
      events: events,
      // events.filter(
      //   (e) => e.date && (e.date === "tba" || new Date(e.date) > yesterDate)
      // ),
      //   breakpoints: {
      //       1800: {
      //           itemsToShow: 3,
      //       },
      //       1650: {
      //         itemsToShow: 2.5,
      //       },
      //       1400: {
      //           itemsToShow: 2,
      //       },
      //       1000: {
      //           itemsToShow: 1.5,
      //       }
      //   },
      width: 0,
    };
  },
  computed: {
    itemsToShow() {
      return Math.max(1, this.width / 600);
    },
  },
  methods: {
    setSize() {
      this.width = window.innerWidth;
    },
  },
  mounted() {
    this.onresize = window.addEventListener("resize", this.setSize);
    this.setSize();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onresize);
  },
};
</script>

<style lang="scss">
.carousel {
  font-size: 31px;
  margin: 2em 0;
  .carousel__viewport {
    overflow: visible;
  }
  .carousel__pagination {
    margin-top: 2em;
    justify-content: right;
    padding-right: 2em;
    &:after,
    &:before {
      content: "YOU'VE MISSED"; //HAPPENING NEXT
      font-family: var(--font-effect);
      font-weight: 400;
      font-style: normal;

      padding-top: 0.45em;
      margin-right: 0.8em;
      font-size: 0.8em;
      color: var(--white);
    }
    &:before {
      display: none;
    }
    .carousel__pagination-item .carousel__pagination-button {
      background-color: var(--white);
      border-radius: 0;
      height: 0.55em;
      width: 0.55em;
      margin: 0.55em;
      &.carousel__pagination-button--active {
        width: 2.2em;
      }
    }
  }
}
@media (max-width: 780px) {
  .carousel .carousel__pagination {
    position: relative;
    &:after {
      display: none;
    }
    &:before {
      display: block;
      position: absolute;
      top: 1.5em;
    }
  }
}
@media (max-width: 600px) {
  .carousel .carousel__pagination {
    font-size: 5vw;
  }
}
</style>