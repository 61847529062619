import _ from "lodash";

// import { store, mutations } from "~/store/store.js";
// eslint-disable-next-line
const OFFSET = 350;



export default {

    data() {

        return {

            scrollPosition: 0

        };

    },

    watch: {

        $route() {

            this.lmS.update();

        }

    },

    mounted() {

        this.$nextTick(
            
            function () {

                window.lmS = new this.locomotiveScroll({

                    el: document.querySelector("#js-scroll"),
                    // repeat: true,
                    // getDirection: true,
                    // getSpeed: true,
                    // scrollFromAnywhere: true,

                    // direction: 'horizontal',
                    smooth: true, /* if false disable overflow: hidden on html, body */
                    resetNativeScroll: false,
                    multiplier: -.25,
                    touchMultiplier: -6,
                    mobile: {
                        breakpoint: 0,    
                        smooth: true
                    },
                    tablet: {
                        breakpoint: 0,    
                        smooth: true
                    },
                });
                window.lmS.scrollToBottom = function (elId){
                    const el = document.getElementById(elId) //'eventslider'
                    // console.log(window.innerHeight, el.offsetHeight)
                    window.lmS.scrollTo(
                      el, {
                        offset: -window.innerHeight+el.offsetHeight 
                      }
                    )
                  }
                window.lmS.scrollTo('bottom', {duration: 0, disableLerp: true})



                window.lmS.on("scroll", _.throttle(this.onLmsScroll, 150));

                window.addEventListener(

                    "resize",

                    _.debounce(this.onLmsResize.bind(this), 100)

                );

            }.bind(this)

        );

    },

    destroyed() {

        window.lmS.destroy();

        window.removeEventListener("resize", this.onLmsResize);

    },

    computed: {

    },

    methods: {

        onLmsScroll(obj) {

            this.scrollPosition = obj["scroll"]["y"];
// eslint-disable-next-line
            // if (this.scrollPosition < OFFSET) {
// eslint-disable-next-line
            // }

        },

        onLmsResize() {

            window.lmS.update();

        }

    }
}