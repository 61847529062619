<template>
  <div class="container home mx-auto reverse-order">
    <h1 class="offscreen">AGGREGAT ERSTE HEPTANOPTISCHE TRANSEXPOSITION</h1>
    <h2 class="offscreen">Countdown</h2>
    <CountdownView />
    <h2 class="offscreen">Wichtige Daten</h2>
    <BasicInfo />
    <h2 class="offscreen">Events</h2>
    <EventsSlider id="eventslider" />
    <h2 class="offscreen">About</h2>
    <AboutText />
    <BgImage v-if="false"/>
    <h2 class="offscreen">Impressum</h2>
    <ImpressumLinks />
  </div>
</template>

<script>
// @ is an alias to /src
import BgImage from "@/components/BgImage.vue";
import locomotive from "@/mixins/locomotive.js";
import CountdownView from "@/components/CountdownView.vue";
import BasicInfo from "@/components/BasicInfo.vue";
import ImpressumLinks from "@/components/ImpressumLinks.vue";
import EventsSlider from "@/components/EventsSlider.vue";
import AboutText from "@/components/AboutText.vue";
export default {
  name: "HomeView",
  mixins: [locomotive],
  components: {
    BgImage,
    CountdownView,
    BasicInfo,
    ImpressumLinks,
    EventsSlider,
    AboutText,
  },
  methods: {
    setRotate() {
      this.$el.style.setProperty("--width", window.innerWidth);
    },
  },
  mounted() {
    this.onresize = window.addEventListener("resize", this.setRotate);
    this.setRotate();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onresize);
  },
};
</script>

<style lang="scss">
</style>
