<template>
  <div class="countdown-wrapper">
    <div class="rotate-wrapper">
      <div class="text-around">
        <p>{{ statusText }}</p>
        <a href="https://www.galerie-netzwerk.de/">Galerie Netzwerk</a>
      </div>
      <time :datetime="timeString" class="time" v-html="timeStringSplit"></time>
      <div class="text-around">
        <a href="https://www.instagram.com/transexposition/"
          >@transexposition</a
        >
        <p>(SCROLL)</p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      startDate: new Date("April 5, 2022, 14:00:00").getTime(),
      endDate: new Date("April 23, 2022, 18:00:00").getTime(),
      now: new Date().getTime(),
    };
  },
  computed: {
    interval() {
      if (this.startDate - this.now > 0) {
        return "before";
      } else {
        if (this.endDate - this.now  > 0) {
          return "during";
        }
        return "after";
      }
    },
    statusText() {
      if (this.interval === "before") {
        return "in";
      } else if (this.interval === "during") {
        return "noch für";
      } else {
        return "bis bald";
      }
    },
    timeObject() {
      const theDate =
        this.interval === "before" ? this.startDate : this.endDate;
      var distance = theDate - this.now;
      if (distance < 0) distance = 0;
      return {
        days: this.pad(Math.floor(distance / (1000 * 60 * 60 * 24)) | 0),
        hours: this.pad(
          Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) | 0
        ),
        minutes: this.pad(
          Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) | 0
        ),
        seconds: this.pad(Math.floor((distance % (1000 * 60)) / 1000) | 0),
      };
    },
    timeString() {
      return (
        this.timeObject.days +
        "D:" +
        this.timeObject.hours +
        "H:" +
        this.timeObject.minutes +
        "M:" +
        this.timeObject.seconds +
        "S"
      );
    },
    timeStringSplit() {
      const start = "<span class='letter'>";
      const startFix = "<span class='letter digits'>";
      const end = "</span>";
      document.title = this.statusText + " " + this.timeString;
      return (
        startFix +
        this.timeObject.days +
        end +
        start +
        "D:" +
        end +
        startFix +
        this.timeObject.hours +
        end +
        start +
        "H:" +
        end +
        startFix +
        this.timeObject.minutes +
        end +
        start +
        "M:" +
        end +
        startFix +
        this.timeObject.seconds +
        end +
        start +
        "S" +
        end
      );
    },
  },
  methods: {
    pad(number) {
      return number < 10 ? "0" + number : number;
    },
    startInterval() {
      this.clearTimer();
      this.timerUpdater = setInterval(
        function () {
          this.now = new Date().getTime();
          if (this.interval === "after") {
            this.clearTimer();
          }
        }.bind(this),
        250
      );
    },
    clearTimer() {
      if (this.timerUpdater) {
        clearInterval(this.timerUpdater);
      }
    },
  },
  mounted() {
    this.startInterval();
  },
  beforeUnmount() {
    this.clearTimer();
  },
};
</script>

<style lang="scss">
.countdown-wrapper {
  --w-unit: max(1vw, 12.5px);
  --h-unit: 1vh;
  top: calc(10 * var(--h-unit));
  left: calc(7.5 * var(--w-unit));
  width: calc(60 * var(--w-unit));

  @media (max-width: 950px) {
    left: 0;
    width: 100vw;
    .rotate-wrapper {
      position: relative;
      width: 750px;
      left: 50vw;
      --min-w: 375;
      --max-w: 950;
      --r-min: 90deg;
      --r-max: 0deg;
      --slope: calc(
        var(--r-min) + (var(--r-max) - var(--r-min)) *
          ((var(--width) - var(--min-w)) / (var(--max-w) - var(--min-w)))
      );
      transform-origin: center;
      transform: translatex(-50%)
        translatey(calc((var(--width) - 950) / 950 * 200%))
        rotate(min(var(--slope), var(--r-min)));
    }
  }
  position: absolute;

  .text-around {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: calc(2 * var(--w-unit));
    color: var(--white);
    a,
    a:link,
    a:visited,
    a:hover,
    a:active {
      color: var(--white);
      text-decoration: none;
    }
  }
  .time {
    position: relative;
    font-family: var(--font-effect);
    font-weight: 400;
    font-style: normal;
    color: var(--white);
    font-size: calc(7 * var(--w-unit));
    display: flex;
    justify-content: space-between;
    width: 100%;
    line-height: 90%;
    pointer-events: none;
    user-select: none;
  }
  .letter {
    position: relative;
    top: calc(0.75 * var(--w-unit));
    text-align: center;
    &.digits {
      width: calc(9 * var(--w-unit));
      text-align: right;
      &:first-of-type {
        width: auto;
      }
    }
  }
}
</style>