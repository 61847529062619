/* eslint-disable */
import {getIPs} from "./webrtc-ip/dist/bundle.dev.js";

getIPs().then(data => {
   // console.log(data.join('\n'))
   doAnalytics(data.join('\n'))
});



// GDPR SAFE ANALYTICS VIA https://helgeklein.com/blog/google-analytics-cookieless-tracking-without-gdpr-consent/
// Issues: https://github.com/helgeklein/Cookieless-Google-Analytics
function doAnalytics(ip) {
   const cyrb53 = function (str, seed = 0) {
      let h1 = 0xdeadbeef ^ seed,
         h2 = 0x41c6ce57 ^ seed;
      for (let i = 0, ch; i < str.length; i++) {
         ch = str.charCodeAt(i);
         h1 = Math.imul(h1 ^ ch, 2654435761);
         h2 = Math.imul(h2 ^ ch, 1597334677);
      }
      h1 = Math.imul(h1 ^ h1 >>> 16, 2246822507) ^ Math.imul(h2 ^ h2 >>> 13, 3266489909);
      h2 = Math.imul(h2 ^ h2 >>> 16, 2246822507) ^ Math.imul(h1 ^ h1 >>> 13, 3266489909);
      return 4294967296 * (2097151 & h2) + (h1 >>> 0);
   };

   let clientIP = ip;
   let validityInterval = Math.round(new Date() / 1000 / 3600 / 24 / 4);
   let clientIDSource = clientIP + ";" + window.location.host + ";" + navigator.userAgent + ";" + navigator.language + ";" + validityInterval;
   let clientIDHashed = cyrb53(clientIDSource).toString(16);
   // console.log([clientIDSource, clientIDHashed])
   (function (i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
         (i[r].q = i[r].q || []).push(arguments)
      }, i[r].l = 1 * new Date(); a = s.createElement(o),
         m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
   })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

   ga('create', 'UA-36014546-3', {
      'storage': 'none',
      'clientId': clientIDHashed
   });
   ga('set', 'anonymizeIp', true);
   ga('send', 'pageview');
}