const events = [
    {
        title: 'Vernissage',
        img: 'hold.png',
        date: '05 April 2022',
        time: '19:00 Uhr',
    },
//     {title: 'tba event',
// date: 'tba'},
    {
        person: 'Viktoriia Tkachuk\nHarry Morrison',
        title: 'Jazz, Benz & Bach',
        img: 'jazz.png',
        date: '07 April 2022',
        time: '19:00 Uhr',
    },
    {
        person: 'Damien Giudice',
        title: 'Boy from Home\nKonzert',
        date: '14 April 2022',
        img: 'boy.png',
        time: '19:00 Uhr'
    },
    {
        title: 'Radio Rituals',
        person: 'de Hohenstein, Schüler, Schüppel',
        img: 'cosmo.png',
        date: '16 April 2022',
        time: '18:00 Uhr'
    },
    {
        title: 'Impromptu Klavier Improvisation',
        person: 'Claudia Driesler',
        img: 'mopi.jpeg',
        date: '21 April 2022',
        time: '19:00 Uhr',
    },
    {
        title: 'Finissage',
        img: 'mosswine5.jpeg',
        date: '22 April 2022',
        time: '19:00 Uhr',
    },
    {
        title: 'Abbau with us',
        img: 'mosspeople.jpeg',
        date: '24 April 2022',
    }
]
export {events}