<template>
  <FillViewport :forceExact="true" class="initial-view" data-scroll-section>
    <CountdownBlock
      data-scroll
      data-scroll-speed="2"
      data-scroll-delay="0.03"
    ></CountdownBlock>
    <RotateElement
      v-for="(banner, id) in banners"
      :rotation="banner.rotation"
      :key="id"
      ><MarqueeText
        :repeat="banner.repeat"
        :duration="banner.speed"
        :reverse="banner.reverse"
        data-scroll
        :data-scroll-position="'bottom'"
        :data-scroll-speed="banner.scrollSpeed"
        data-scroll-delay="0.03"
        ><LogoSvg height="18vh" fill="var(--teal)" /></MarqueeText
    ></RotateElement>
    <div class="safari-sucks">
      <p class="h">Moin!</p>
      <p>
        Diese wunderschöne Website verträgt sich leider nicht gut mit Safari.
      </p>
      <p>
        Wie du bemerkt haben wirst ist hier unten nichts, denn du, Nutzer, musst
      </p>
      <p class="bold">NACH OBEN SCROLLEN</p>
      <p>um die Inhalte dieses Meisterwerks zu komsumieren.</p>
      <p>Liebe Grüße,</p>
      <p>Der Verfasser</p>
    </div>
  </FillViewport>
</template>

<script>
import FillViewport from "@/components/FillViewport.vue";
import MarqueeText from "vue-marquee-text-component";
import LogoSvg from "@/components/LogoSvg.vue";
import RotateElement from "@/components/RotateElement.vue";
import CountdownBlock from "@/components/CountdownBlock.vue";
export default {
  components: {
    FillViewport,
    MarqueeText,
    LogoSvg,
    RotateElement,
    CountdownBlock,
  },
  data() {
    return {
      banners: [
        { rotation: -9, speed: 12, repeat: 6, reverse: false, scrollSpeed: -3 },
        { rotation: 21, speed: 20, repeat: 6, reverse: false, scrollSpeed: -4 },
        { rotation: -5, speed: 40, repeat: 6, reverse: true, scrollSpeed: -2 },
      ],
    };
  },
};
</script>

<style lang="scss">
.initial-view {
  position: relative;
  background-color: var(--teal);
  @media (max-width: 950px) {
    margin-top: calc((var(--width) - 950) / 950 * -120%);
  }

  .rotate {
    position: relative;
    top: 61.75vh;

    left: -5vw;
    pointer-events: none;
    user-select: none;
  }
  .marquee-text-wrap {
    position: relative;
    width: 200%;
    left: -50%;
    // transform: translateX(-33.33%);
    background-color: #fff;
    box-shadow: 0 0 12px #0005;
    margin-bottom: -8.125vh;
    .logo {
      padding: 2vh 4vh;
      // background: red;
    }
  }

  .safari-sucks {
    padding: 1em;
    position: absolute;
    top: 105%;
    width: 100vw;
    color: var(--white);
    .h, .bold {
      font-size: 2em;
      font-family: var(--font-effect);
      font-weight: 400;
      font-style: normal;
    }
  }
}
</style>