<template>
  <div :style="{'transform-origin': origin, 'transform':' rotate('  +rotation + 'deg)'}" class="rotate"><slot></slot></div>
</template>

<script>
export default {
    props: {
        rotation: {
            default: 0
        },
        origin: {
            default: 'center'
        }
    }
}
</script>

<style>

</style>