<template>
  <section :class="['fill-viewport', forceExact ? 'force-exact' : '', minExact ? 'min-exact' : '']"><slot></slot></section>
</template>

<script>
export default {
    props: [
        'forceExact',
        'minExact'
    ]
}
</script>

<style>
.fill-viewport{
    min-width: 100vw;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    
}
.force-exact{
    width: 100vw;
    height: 100vh;
    overflow-y: visible;
}
.min-exact{
    width: 100vw;
    min-height: 100vh;
}
</style>