<template>
  <section data-scroll-section class="about-text" >
    <BgImage />
    <div class="text-container" id="about-text">
      <div
        class="snippet-container"
        v-for="(string, index) in texts"
        :key="index"
        :id="'text-block-target-' + index"
        data-scroll
        data-scroll-direction="horizontal"
        datal-scroll-speed="2"
      >
        <div
          class="snippet-inner"
          data-scroll
          data-scroll-position="bottom"
          data-scroll-repeat="true"
          data-scroll-sticky
          :data-scroll-target="'#text-block-target-' + index"
        >
          <p>
            {{ string }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="decor"
      data-scroll
      data-scroll-position="bottom"
      data-scroll-repeat="true"
      data-scroll-offset="50%, 50%"
      data-scroll-sticky
      data-scroll-target="#about-text"
    >
      <div class="d1"></div>
      <div class="d2"></div>
      <div class="d3"></div>
    </div>
  </section>
</template>

<script>
import BgImage from "@/components/BgImage.vue";
export default {
  components: {
    BgImage,
  },
  data() {
    return {
      texts: [
        "Wer das Wort Ausstellung ausspricht ordnet sich in einer Genealogie von\
        Art for Profit, art pour l'art, Counter-Art, Kunst für die Massen, für\
        das Selbst, und für niemanden ein.",
        " Die Transexposition wandert diesen\
        Grat während sie versucht, den Ballast der Institutionen und des Profits\
        abzuwerfen, und somit auch den Namen >Ausstellung<.",
        "Das Resultat\
        ist eine Ganzheitliche Installation, Verschmelzung von Ideen,\
        Kunstwerken und Praxis.",
        "Die Autokratie der Weißen Räume wird zugunsten\
        einer Gemeinschaft der Kreativität aufgelöst.",
        "Diese Ansammlung von Idealen, Ideen und Individuen nimmt\
        >Aggregat< zum Subjekt.",
        "Die sieben Künstler*innen der\
        Heptanoptischen Transexposition, punktuell unterstützt von multimedialen\
        Gästen, und im expliziten Dialog mit allen Gästen, erforschen gemeinsam\
        die Dimensionen einer zufälligen Ansammlung.",
        "Beleuchtet werden die\
        Facetten der menschgemachten, natürlichen, sowie konzeptuellen Häufungen\
        und Konstrukte, derer Wirken, Zusammenwirken und Einfluss.",
        "Wir laden ein, Teil dieses Fragments der Sprengung von Konventionen zu\
        werden, zu spüren, betrachten, hören und laut mitzugestalten, was nach\
        dem Status Quo lebt.",
      ],
    };
  },
  mounted() {
    // window.lmS.init()
  },
};
</script>

<style lang="scss">
.about-text {
  width: 100vw;
  margin: 5vh 0 50vh 0;
  position: relative;
  .text-container {
    font-size: 1.333em;
    color: var(--white);
    text-transform: none;
    position: relative;
    display: flex;
    flex-direction: column-reverse;

    .snippet-container {
      height: 33vh;
      margin: 1.5em 0;
      width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .snippet-inner {
      position: relative;
      top: 25vh;
      &.is-inview {
        // width: 100vw;
        // height: 100vh;
        // position: fixed;
        // left: 0;
        // top: 0;
        p {
          opacity: 1;
          transition-duration: 1s;
          transform: perspective(400px) translateZ(0px);
        }
      }
    }

    p {
      
          transform: perspective(400px) translateZ(-20px);
      max-width: 35em;
      margin-right: 4.5em;
      margin-left: auto;
      
        padding: 0 2.5em;
      // position: relative;
      opacity: 0.5;
      transition: opacity 0s cubic-bezier(0.215, 0.61, 0.355, 1),
        transform 0s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition-duration: 5s;
    }
  }
  // height: 200vh;
  .decor {
    width: 100%;
    height: 0;
    position: absolute;
    top: 0;
    div {
      position: relative;
    }
    .d1 {
      position: absolute;
      top: 75vh;
      left: 2vw;
      width: 50vh;
      height: 100vh;
      // border: 1em solid var(--teal);
      border-radius: 25vh;
      background-color: var(--white);
      --rotation: 0deg;
      --transX: 0%;
      animation: float 4s ease-in-out infinite;
    }
    .d2{
      position: absolute;
      top: 115vh;
      left: 20vw;
      width: 25vh;
      height: 50vh;
      border: 1em solid var(--teal);
      border-radius:17.5vh;
      background-color: var(--white);
      --rotation: 0deg;
      --transX: 0%;
      animation: float 3s ease-in-out infinite;
    }
    .d3{
      position: absolute;
      top: 80vh;
      right: -9vh;
      width: 18vh;
      height: 36vh;
      // border: 1em solid var(--teal);
      border-radius: 9vh;
      background-color: var(--white);
      --rotation: 0deg;
      --transX: 0%;
      animation: float 5s ease-in-out infinite;
    }
  }
  @media (max-width: 1750px){
    .decor {
      .d2{
      left: 10vh;
    }
    .d1{
      left: -25vh;
    }
    }
  }
    @media (max-width: 1500px){
      .decor {
        display: none;
      }
      .snippet-container .snippet-inner p{
        margin: 0 auto;
      }
    }
    @media (max-width: 900px){
      .text-container .snippet-container {
      height: 50vh;
      }
    }
    @media (max-width: 650px){
      
  margin: -15vh 0 50vh 0;
      .text-container .snippet-container {
      height: 100vh;
      margin: 2.5em 0;
      .snippet-inner{
        top: 0vh;
        font-size: max(5vw, 16px);
      }
      }
    }
}
</style>