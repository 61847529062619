<template>
  <section
    data-scroll-section
    class="legal-links"
    :class="isImpressum ? 'stick' : ''"
    @mouseenter.passive="isPaused = true"
    @mouseleave.passive="isPaused = false"
    @touchstart.passive="isPaused = true"
    @touchend.passive="isPaused = false"
  >
    <MarqueeText v-if="!isImpressum" :paused="isPaused" :repeat="4" :duration="20" :reverse="true"
      ><router-link to="/impressum">Impressum</router-link
      ><a href="https://www.galerie-netzwerk.de/">galerie-netzwerk.de</a
      ><router-link to="/dsgvo">DSGVO</router-link
      ><a href="mailto:heptanoptische@transexposition.website"
        >heptanoptische@transexposition.website</a
      ><a href="https://pulp19.com/">pulp19.com</a></MarqueeText
    >
    <MarqueeText v-else :paused="isPaused" :repeat="4" :duration="20" :reverse="true"
      ><router-link to="/impressum">Impressum</router-link
      ><router-link to="/dsgvo">DSGVO</router-link
      ><router-link to="/">Aggregat</router-link
      ></MarqueeText
    >
  </section>
</template>

<script>
import MarqueeText from "vue-marquee-text-component";
export default {
  components: {
    MarqueeText,
  },
  props: [
    'isImpressum'
  ],
  data() {
    return {
      isPaused: false,
    };
  },
};
</script>

<style lang="scss">
.legal-links {
  background-color: var(--teal);
  color: blue;
  a {
    padding: 1em;
    text-decoration: none;
    color: var(--white);
    &:hover, &:active{
        color: var(--black);
    }
  }
  &.stick{
    position: sticky; top: 0;
  }
}
</style>